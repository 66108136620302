<template>
  <section class="coupon-setting" :v-loading="loading">
    <PageTitle
      title="點數卡模組設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section class="section-block">
      <SectionTitle title="點數卡設定" @edit="showDialog = true" />
      <BaseElForm
        v-model="formData"
        label-position="left"
        label-width="250px"
      >
        <BaseElFormItem label="顯示名稱" prop="displayName">
          <div class="text-[#5E5E5E]">{{ formData.displayName }}</div>
        </BaseElFormItem>
        <BaseElFormItem label="到期通知發送天數" props="expireNotifyDays">
          <div class="text-[#5E5E5E]">{{ formData.expireNotifyDays }}天</div>
        </BaseElFormItem>
      </BaseElForm>
      <p class="text-sub text-gray-60">備註：若點數卡效期小於到期通知發送天數，則持有該卡的會員不會收到點數卡到期通知</p>
    </section>
    <PointCardSettingsEditModal
      v-if="showDialog"
      :configData="configData"
      @close="showDialog = false"
      @refresh="getPointCardConfig"
    />
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { GetPointCardConfig } from '@/api/pointCard'
import PointCardSettingsEditModal from './components/PointCardSettingsEditModal.vue'
import store from '@/store'

export default defineComponent({
  name: 'PointCardSettings',
  components: { PointCardSettingsEditModal },
  setup (props) {
    const loading = ref(false)
    const formData = reactive({
      displayName: '',
      expireNotifyDays: 14,
    })
    const showDialog = ref(false)
    const configData = ref({})
    const shopId = computed(() => store.getters.shop)

    const getPointCardConfig = async () => {
      loading.value = true
      const [res, err] = await GetPointCardConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
      formData.displayName = res.displayName || '點數卡'
      formData.expireNotifyDays = res.expireNotifyDays || 14
    }

    onMounted(() => {
      getPointCardConfig()
    })
    return {
      loading,
      formData,
      showDialog,
      configData,
      getPointCardConfig,
    }
  },
})
</script>
