<template>
  <BaseDialog
    :show="true"
    :btn1Loading="loading"
    title="點數卡設定"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <BaseElFormItem label="顯示名稱" prop="displayName">
        <BaseElInput
          v-model="formData.displayName"
          clearable
          :maxlength="5"
          show-word-limit
        />
      </BaseElFormItem>
      <BaseElFormItem label="到期通知發送天數" prop="expireNotifyDays">
        <BaseElInput v-model="formData.expireNotifyDays" clearable>
          <template #suffix>天</template>
        </BaseElInput>
      </BaseElFormItem>
    </BaseElForm>
    <p class="text-sub text-gray-60 mb-[40px]">備註：若點數卡效期小於到期通知發送天數，則持有該卡的會員不會收到點數卡到期通知</p>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm, simpleSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { useShop } from '@/use/shop'
import { UpdatePointCardConfig } from '@/api/pointCard'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: '',
  components: {
    BaseDialog,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const {
      initFormData,
      formData,
      formRef,
      loading,
      checkForm,
    } = useBaseForm()
    const { shopId } = useShop()

    initFormData({
      displayName: '',
      expireNotifyDays: 14,
    })

    const formRules = computed(() => {
      const rules = {
        displayName: [noEmptyRules()],
        expireNotifyDays: [noEmptyRules()],
      }
      return rules
    })

    const updatePointCardConfig = async () => {
      loading.value = true
      const [, err] = await UpdatePointCardConfig({
        shopId: shopId.value,
        displayName: formData.displayName,
        expireNotifyDays: formData.expireNotifyDays,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true

      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      await updatePointCardConfig()
    }

    onMounted(() => {
      simpleSyncFormData(props.configData, formData)
    })

    return { formData, formRules, formRef, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
