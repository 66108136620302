import { render, staticRenderFns } from "./PointCardSettingsEditModal.vue?vue&type=template&id=539d8924&scoped=true"
import script from "./PointCardSettingsEditModal.vue?vue&type=script&lang=js"
export * from "./PointCardSettingsEditModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "539d8924",
  null
  
)

export default component.exports